<template>
  <section class="page-profile">
    <div class="banner">
      <p class="title">COMPANY PROFILE</p>
    </div>
    <div class="content" :class="{ fold: isFold }">
      <p>
        Prestigious real estate investment enterprise, with total assets under
        management of $2.8 billion, JWP based on diversified specialties of Real
        Estate Development and Investment Fund, providing superior asset
        management services to High Net Worth Individuals around the world,
        succeed from Asia, European, to North America’s demanding markets,
        shares the value of symbiosis benefits from win-win results.
      </p>
      <p>
        The property categories of JWP real estate development contains culture
        tourism resort, villa, residential, nursing home, and student apartment,
        from more than 100 real estate projects, 40 cities, 9 countries
        globally, meeting the high-end’s requirements of cross-border property
        ownership and the assets allocation overseas. As one of the first real
        estate enterprises to carry out global layout and the most reliable
        cross-border investment services brand recognition, JWP real estate
        development promotes a prefabricated building approach actively with
        integrated intelligent residential highlights, conducts green ecology
        and environment-friendly.
      </p>
      <p>
        In the meantime, JWP funds adheres to the principle of risk control and
        provides clients with professional screening, asset allocation, and
        portfolio management services for all types of financial investment
        products in the location of the fund. As of 2019, JWP's managed funds
        include the Ireland JWP ICAV, Portugal JWP FUND, and Cyprus JWP AIF
        V.C.I.C. PLC. JWP FUNDS has participated with the Central Bank of
        Ireland (CBI), Portugal Securities Market Commission (CMVM), European
        Central Bank (ECB), Cyprus Hospitality Industry, Renewable Energy
        Sector, and other high- quality resources and enterprises within EU,
        building the investment growth model with real estate funds as the heart
        core.
      </p>
      <p>
        With the implementation of Real Estate Finance and Real Estate
        Development, JWP updating its first-class products and services,
        appreciating the renowned symbiosis benefits between customer and
        services, defeated all challenges and competitions from international
        financial and real estate market, looking for the reliable and excellent
        business opportunities in the scope of overseas investments
        indefatigably.
      </p>
    </div>
    <div class="look-more">
      <div class="opacity" v-if="isFold">
        <img src="//static.hoolihome.com/global-jwp/assets/m/text-mask.png" />
      </div>
      <div class="btn" @click="handleLookMore">
        <span>{{ btnText }}</span>
        <img
          :src="
            isFold
              ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
              : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
          "
        />
      </div>
    </div>
    <video-play></video-play>
    <div class="recognition">
      <p class="title">
        <span class="van-hairline--bottom">JWP Brand Recognition</span>
      </p>
      <img
        class="map"
        src="//static.hoolihome.com/global-jwp/assets/m/landmark/map-en.png"
      />
    </div>
    <project-list :projects="projects"></project-list>
  </section>
</template>

<script>
import VideoPlay from "@/components/about/VideoPlay";
import ProjectList from "@/components/project/ProjectList";

export default {
  components: { VideoPlay, ProjectList },
  data() {
    return {
      isFold: true,
      btnText: "View more",
      projects: [
        {
          id: "15",
          projectImg:
            "//files.hoolihome.com/jwp/project/0302/20200302133903_553.png",
          countryText: "THAILAND",
          name: "LAYA RESORT",
          propertyLocation: "Phuket",
          architecturalForm: "Deluxe Resort hotel",
          marketEntryTime: "2018"
        },
        {
          id: "16",
          projectImg:
            "//files.hoolihome.com/jwp/project/0305/20200305114204_763.jpg",
          countryText: "THAILAND",
          name: "BAAN YARD",
          propertyLocation: "Phuket",
          architecturalForm: "Deluxe Resort hotel",
          marketEntryTime: "2019"
        },
        {
          id: "8",
          projectImg:
            "//files.hoolihome.com/jwp/project/0305/20200305115705_105.jpg",
          countryText: "CYPRUS",
          name: "SIXTY6 TOWER",
          propertyLocation: "Limassol",
          architecturalForm: "Signature Design Apartment",
          marketEntryTime: "2019"
        },
        {
          id: "130",
          projectImg:
            "//files.hoolihome.com/jwp/project/0529/20200529161651_571.jpg",
          countryText: "CYPRUS",
          name: "PEARL HOUSE 7",
          propertyLocation: "Larnaca",
          architecturalForm: "Apartment",
          marketEntryTime: "2019"
        },
        {
          id: "102",
          projectImg:
            "//files.hoolihome.com/jwp/project/0316/20200316095522_346.png",
          countryText: "TURKEY",
          name: "G ROTANA",
          propertyLocation: "Istanbul",
          architecturalForm: "Deluxe Serviced Apartment",
          marketEntryTime: "2019"
        },
        {
          id: "27",
          projectImg:
            "//files.hoolihome.com/jwp/project/0307/20200307145427_231.jpg",
          countryText: "GREECE",
          name: "ACROPOLI COLLECTION XVIII",
          propertyLocation: "Athens",
          architecturalForm: "Residential Apartment",
          marketEntryTime: "2019"
        },
        {
          id: "44",
          projectImg:
            "//files.hoolihome.com/jwp/project/0308/20200308152526_633.jpg",
          countryText: "GREECE",
          name: "SUPERIOR COLLECTION-THE PLACE I",
          propertyLocation: "Athens",
          architecturalForm: "Deluxe Apartment",
          marketEntryTime: "2019"
        },
        {
          id: "",
          projectImg:
            "//static.hoolihome.com/global-jwp/assets/img/commercial/img5.png",
          countryText: "IRELAND",
          name: "KILCOOLE LODGE NURSING HOME",
          propertyLocation: "Dublin",
          architecturalForm: "Nursing Home",
          marketEntryTime: "2016"
        },
        {
          id: "",
          projectImg:
            "//static.hoolihome.com/global-jwp/assets/img/about/img3.png",
          countryText: "JAPAN",
          name: "一戸建て",
          propertyLocation: "Osaka",
          architecturalForm: "Detached house",
          marketEntryTime: "2019"
        },
        {
          id: "94",
          projectImg:
            "//files.hoolihome.com/jwp/project/0312/20200312120722_268.png",
          countryText: "PORTUGAL",
          name: `CONDONINIUM QUINTA DA PEDRA D'ÁGUA`,
          propertyLocation: "Lisbon",
          architecturalForm: "Terraced house",
          marketEntryTime: "2017"
        }
      ]
    };
  },
  beforeCreate() {
    document.title = "Enterprise Profile";
  },
  methods: {
    handleLookMore() {
      if (this.isFold) {
        this.isFold = false;
        this.btnText = "Collapse";
      } else {
        this.isFold = true;
        this.btnText = "View more";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page-profile {
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 158px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/about/profile-banner.jpg")
      no-repeat center center / cover;
    .title {
      margin-top: 22px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
  .content {
    padding: 16px 22px;
    transition: all 0.3s;
    &.fold {
      height: 71px;
      overflow: hidden;
    }
    p {
      font-size: 14px;
      color: rgba(38, 38, 38, 1);
      line-height: 20px;
      &:not(:last-child) {
        padding-bottom: 10px;
      }
    }
  }
  .look-more {
    padding: 0 22px;
    .opacity {
      height: 20px;
      margin: -20px 0 16px;
      img {
        width: 100%;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      border-radius: 4px;
      border: 1px solid rgba(38, 38, 38, 1);
      &:active {
        opacity: 0.8;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
      }
      img {
        width: 8px;
        margin-left: 8px;
      }
    }
  }
  .recognition {
    padding: 13px 22px 40px;
    text-align: center;
    .title {
      padding-bottom: 22px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .map {
      width: 335px;
    }
  }
  .project-list {
    margin-bottom: 40px;
  }
}
</style>
