<template>
  <div class="video-player">
    <template v-show="ready">
      <video
        :id="videoId"
        :poster="videoItem && videoItem.poster"
        class="video-js vjs-big-play-centered"
        controls
        preload="auto"
      />
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoId: "myVideo",
      videoItem: {
        poster: "//static.hoolihome.com/global-jwp/assets/video/profile.jpeg",
        videoUrl:
          "//static.hoolihome.com/global-jwp/assets/video/profile/profile.m3u8"
      },
      myVideoPlayer: null,
      ready: false
    };
  },
  created() {
    this.$utils
      .loadSrc(
        "//static.hoolihome.com/common/video.js/video-js.min.css",
        "VIDEOPLAYCSS",
        "css"
      )
      .then(() => {
        this.$utils
          .loadSrc(
            "//static.hoolihome.com/common/video.js/video.min.js",
            "VIDEOPLAYJS",
            "js"
          )
          .then(() => {
            window.setTimeout(() => {
              this.handlePlayVideo();
            }, 100);
          });
      });
  },
  methods: {
    handlePlayVideo() {
      if (!window.videojs) {
        window.alert("Load video resource fail, please refresh to try.");
        return false;
      }
      this.$nextTick(() => {
        if (!this.myVideoPlayer) {
          this.myVideoPlayer = window.videojs(this.videoId, {
            controls: true
          });
        }
        let mimeType = "";
        if (this.videoItem.videoUrl.toLowerCase().includes(".m3u8")) {
          mimeType = "application/x-mpegURL";
        } else if (this.videoItem.videoUrl.toLowerCase().includes(".mp4")) {
          mimeType = "video/mp4";
        }
        this.myVideoPlayer.src({
          src: this.videoItem.videoUrl,
          type: mimeType
        });
        window.setTimeout(() => {
          this.ready = true;
        }, 50);
      });
    }
  }
};
</script>

<style lang="less">
.video-player {
  .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
  }
  .video-js .vjs-big-play-button {
    font-size: 2.5em;
    line-height: 2.3em;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    margin-top: -1.25em;
    margin-left: -1.2em;
  }
  /* 中间的播放箭头 */
  .vjs-big-play-button .vjs-icon-placeholder {
    position: relative;
    top: 4px;
    font-size: 1.2em;
  }
  /* 加载圆圈 */
  .vjs-loading-spinner {
    font-size: 2.5em;
    width: 2em;
    height: 2em;
    border-radius: 1em;
    margin-top: -1em;
    margin-left: -1.5em;
  }
  .video-js.vjs-playing .vjs-tech {
    pointer-events: auto;
  }
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
  .video-js .vjs-modal-dialog,
  .vjs-button > .vjs-icon-placeholder:before,
  .vjs-modal-dialog .vjs-modal-dialog-content {
    position: relative;
  }
}
</style>

<style lang="less" scoped>
.video-player {
  position: relative;
  display: flex;
  min-height: 186px;
  padding: 24px 22px;
  /deep/ .video-js {
    flex: 1;
    height: 186px;
    border-radius: 4px;
    overflow: hidden;
  }
}
</style>
